import React, { Suspense, lazy, useEffect } from 'react';
import { Routes, Route, BrowserRouter } from "react-router-dom";
import kalyanLogo from '../Images/KM logo@2x.png'
import LoaderGif from "../Gif/loader.gif"


const CampaignLandingPage = lazy(() => import('../Pages/LandingPages/CampaignLandingPage'));
const MainApp = lazy(() => import('./MainApp'));

function Main() {
  useEffect(() => {
    let loginDevice = window.innerWidth < 1024 ? "Mobile Site" : "Desktop"
    const finalLoginDevice = loginDevice || "Unknown";
    localStorage.setItem("LOGIN_DEVICE", finalLoginDevice)
  }, [])
  return (
    <BrowserRouter>
      <Suspense fallback={
        <div style={{ height: "100vh", width: "100%", display: "flex", justifyContent: "center", alignItems: "center" }}>
          <div style={{ display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center" }}>
            <img alt="kalyan" width="200" src={kalyanLogo} />
            <img alt="" width="50" src={LoaderGif} />
          </div>
        </div>
      }>
        <Routes>
          <Route path='/campaign/*' element={<CampaignLandingPage page="google" />} />
          <Route path="*" element={<MainApp />} />
        </Routes>
      </Suspense>
    </BrowserRouter>
  );
}

export default Main;